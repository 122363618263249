<template>
  <div data-app>
    <!-- Content -->
    <v-card>
      <div class="filters">
        <help class="help--crud" :helpId="33" />
        <h2 class="text-center">
          Asignación de documentos
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >Asignar
              </v-btn>
            </template>
            <span>Asignar Documentos</span>
          </v-tooltip>
        </h2>
        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 10px)">
            <v-col cols="12" lg="4" md="4" sm="6">
              <v-row no-gutters>
                <span style="width: 30%">Empresa: </span>
                <multiselect
                  style="width: 70%"
                  track-by="id"
                  label="name"
                  deselect-label="Remover"
                  select-label="Seleccionar"
                  placeholder="Seleccionar"
                  v-model="filters.company"
                  :options="companies"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                  @search-change="getCompanies"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6">
              <v-text-field
                dense
                clearable
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6">
              <v-text-field
                dense
                clearable
                hide-details="auto"
                label="Vencen antes de"
                v-model="filters.expiredAt"
                type="date"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            style="min-width: auto; position: absolute; right: 50px; height: 25px"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getAssignedDocuments()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getAssignedDocuments()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'80%'" :adaptive="false">
      <v-card elevation="0" style="padding: 10px 1rem">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Asignar documentos</h2>
        <help class="help--card" :helpId="34" />
        <h2 class="text-center mb-4" v-if="flow === 'edit'">
          Editar asignación de documento<help
            class="mx-2"
            :helpId="62"
            :xSmall="true"
            style="vertical-align: baseline"
          />
        </h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >

        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row class="mx-0 mt-2">
            <v-col cols="12" md="6">
              <v-row no-gutters>
                <span style="width: 30%">Asignar a:</span>
                <multiselect
                  style="width: 70%"
                  track-by="id"
                  label="name"
                  deselect-label="Remover"
                  :disabled="flow === 'edit'"
                  select-label="Seleccionar"
                  placeholder="Seleccione"
                  :allow-empty="false"
                  v-model="selectedCompanies"
                  :options="companies"
                  :multiple="flow !== 'edit'"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                  @search-change="getCompanies"
                />
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row no-gutters>
                <span style="width: 40%">Documentos:</span>
                <multiselect
                  style="width: 60%"
                  track-by="id"
                  label="name"
                  :disabled="flow === 'edit'"
                  deselect-label="Remover"
                  select-label="Seleccionar"
                  placeholder="Seleccione"
                  v-model="selectedDocuments"
                  :options="documents"
                  :allow-empty="false"
                  :multiple="flow !== 'edit'"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                  @search-change="getDocuments"
              /></v-row>
            </v-col>
            <v-col cols md="6">
              <v-text-field
                :rules="[rules.required]"
                label="Fecha de expiración"
                v-model="expiredAt"
                type="date"
              />
            </v-col>
            <v-col cols="12" md="6" sm="12" v-if="flow === 'edit'">
              <div class="row" style="display: flex; justify-content: center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      style="width: 10%"
                      v-if="item.file && item.file !== undefined && item.file.route"
                      ><a :href="item.file.route" alt download
                        ><v-icon>mdi-download</v-icon></a
                      ></v-btn
                    ></template
                  ><span>Descargar documento</span></v-tooltip
                >
                <v-file-input
                  v-model="file"
                  chips
                  :rules="[rules.fileSize]"
                  ref="file"
                  show-size
                  accept=".doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx"
                  :label="'Seleccione un archivo'"
                  style="width: 60%"
                />
              </div>
            </v-col>

            <v-col
              cols="12"
              v-if="item.file && item.file !== undefined && item.file.route"
              ><iframe
                :src="`https://docs.google.com/gview?url=${item.file.route}&embedded=true`"
                style="width: 100%; height: 600px"
                frameborder="0"
            /></v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-col cols="12" style="height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="massiveAssignment()"
          >
            Asignar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn style="margin: auto; margin-top: 10px" color="primary" @click="reset">
            Limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  /* eslint-disable */
  import { mapGetters } from 'vuex'
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  import Help from '@/components/App/Help'
  const date = new Date()

  export default {
    name: 'CompanyDocuments',
    components: {
      SimpleList,
      Help,
    },
    data() {
      return {
        tab: 0,
        valid2: true,
        valid3: true,
        config: {},
        loading: false,
        isLoading: false,
        metricPositionModal: false,
        metricPositionUserModal: false,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        menu: false,
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Empresa', value: 'company.name', align: ' font-weight-bold' },
          { text: 'Documento', value: 'document.name' },
          { text: 'Fecha de vencimento', value: 'expiredText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        page: 1,
        pages: 1,
        item: {},
        companies: [],
        selectedCompanies: null,
        documents: [],
        selectedDocuments: null,
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Fecha de vecimiento', order: 'expired_at', by: 'asc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Fecha de vecimiento', order: 'expired_at', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          company: null,
          expiredAt: new Date(new Date().setMonth(new Date().getMonth() + 3))
            .toISOString()
            .split('T')[0],
        },
        expiredAt: '',
        file: null,
        loadingText: 'Cargando registros',
        name: '',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            v ? v.length <= 100 : true || 'Debe poser menos de 100 caracteres',
          maxNumber: (v) =>
            v ? v.length <= 18 : true || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    watch: {},
    created() {
      this.filters.company = this.company
      this.getDocuments()
      this.getCompanies()
      this.getAssignedDocuments()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set() {},
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
          company: this.company,
        }
      },

      async getCompanies(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('companies?order=name&by=asc&limit=500' + searchStr)
          .then((response) => {
            this.companies = response.data
          })
      },

      async getAssignedDocuments() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        let companyString = ''
        let dateString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.expiredAt) {
            dateString = '&expired_at=' + this.filters.expiredAt
          }
          if (this.filters.company && this.filters.company.id) {
            companyString = '&company_id=' + this.filters.company.id
          }
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString + dateString + companyString
        }
        this.$axios
          .get('company-documents?page=' + this.page + orderString + queryParams)
          .then((response) => {
            this.items = response.data.data
            if (this.items && this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((assigned) => {
              assigned.expiredText = toLocaleDateString(assigned.expired_at)
              assigned.file = assigned.uploads.find((file) => file.type === 'document')
            })
            this.loading = false
          })
      },

      async getDocuments(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'documents?withoutCompany=1&order=name&by=asc&limit=100' +
              searchStr +
              '&company_id=' +
              this.company.id
          )
          .then((response) => {
            this.documents = response.data
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.filters.company = this.company
        this.selectedCompanies = [this.company]
        this.selectedDocuments = []
        this.expiredAt = ''
        this.file = null

        this.$modal.show('crudModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getAssignedDocuments()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.selectedCompanies = this.item.company
        this.selectedDocuments = this.item.document
        this.expiredAt = this.item.expired_at.split(' ')[0]
        this.file = null
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      massiveAssignment() {
        let assign = []
        this.selectedCompanies.forEach((company) => {
          this.selectedDocuments.forEach((document) => {
            assign.push({
              company_id: company.id,
              document_id: document.id,
              expired_at: this.expiredAt,
            })
          })
        })
        this.$axios
          .post('company-document-mass-create', {
            companyDocument: assign,
          })
          .then(() => {
            this.page = 1
            this.getAssignedDocuments()
            this.$modal.hide('crudModal')
          })
      },

      async saveItem() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }

        const formData = new FormData()
        formData.append('company_id', this.selectedCompanies.id)
        formData.append('document_id', this.selectedDocuments.id)
        formData.append('expired_at', this.expiredAt)
        if (this.file && this.file !== '' && this.file !== undefined) {
          formData.append('file', this.file)
          formData.append('fileType', 'document')
          if (this.item.file && this.item.file.id) {
            formData.append('fileId', this.item.file.id)
          }
        }
        formData.append('_method', 'patch')
        this.$axios
          .post('company-documents/' + this.item.id, formData, this.config)
          .then(() => {
            this.page = 1
            this.getAssignedDocuments()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        this.$axios.delete('company-documents/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getAssignedDocuments()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        this.getAssignedDocuments()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .v-date-picker-title__date {
    font-size: inherit;
  }
  .v-dialog {
    margin: 0px;
    background-color: #ffffff;
  }
</style>
