<template>
  <v-container fluid>
    <div class="vuse-content-wrapper" data-app>
      <smart-breadcrumbs :items="navigationElements" />

      <v-card>
        <v-toolbar color="primary" dark height="50" class="p-0">
          <v-tabs v-model="model" centered slider-color="teal" class="m-0">
            <v-tab href="#tab-1"> Documentos </v-tab>
            <v-tab href="#tab-2"> Asignación de Documentos </v-tab>
          </v-tabs>
        </v-toolbar>

        <v-tabs-items v-model="model">
          <v-tab-item value="tab-1">
            <v-card flat>
              <metrics />
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card flat>
              <company-documents />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  import Metrics from '@/components/SupervisionControl/Documents'
  import CompanyDocuments from '@/components/SupervisionControl/CompanyDocuments'

  export default {
    components: {
      Metrics,
      CompanyDocuments,
    },
    data: () => ({
      model: 'tab-2',
      navigationElements: [
        {
          text: 'Control y Supervisión',
          disabled: false,
          query: null,
          params: null,
          name: 'business/supervision-control/Dashboard',
          href: '/business/supervision-control/admin-panel',
        },
        {
          text: 'Gestor de Documentos',
          disabled: true,
          href: '',
        },
      ],
    }),
  }
</script>
<style lang="scss">
  .v-breadcrumbs {
    padding: 5px 10px !important;
  }
  .v-tabs-slider {
    margin-top: -5px;
  }
</style>
